<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <h1 class="text-center">Sistema Integrado de Gestão Escolar</h1>
        <h5 class="text-center">Universo da Criança</h5>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/views/layouts/Layout.vue'
import PageHeader from '@/components/commons/PageHeader.vue'

export default {
  name: 'Home',

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: 'Home',
      items: [
        {
          text: 'Home',
          active: true,
        },
      ],
    }
  },
}
</script>
